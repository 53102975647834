@import "../../../scss/mixins/reset.scss";
@import "../../../scss/mixins/text.scss";
@import "../../../scss/mixins/basic.scss";
@import "../../../scss/mixins/utility.scss";
@import "../../../scss/variables.scss";

.filled {
  @include reset-button();
  @include textRegular-12();
  @include basic-button();
  align-items: center;
  animation: gradientLine 2s linear infinite;
  background: $bright-orange-gradient;
  background-size: 200% auto;
  border-radius: 8px;
  color: $basic-black;
  font-weight: $font-weight-medium;
  letter-spacing: 0.36px;
  padding: 14px 28px;
  position: relative;
}

.purple {
  @include reset-button();
  @include textRegular-12();
  @include basic-button();
  align-items: center;
  animation: gradientLine 2s linear infinite;
  background: $purpur-gradient;
  background-size: 200% auto;
  border-radius: 8px;
  color: $basic-white;
  font-weight: $font-weight-medium;
  padding: 15px 11px;
  position: relative;
  width: 135px;
}

.price {
  @include reset-button();
  @include textRegular-12();
  @include basic-button();
  align-items: center;
  backdrop-filter: blur(5px);
  background: #303041;
  border-radius: 8px;
  color: $basic-white;
  font-weight: $font-weight-medium;
  padding: 12px 14px;
  position: relative;
  width: 94px;
}

@keyframes gradientLine {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.icon {
  @include vertical-center();
  display: flex;
  left: 18px;
}

.disabled {
  background: $stormy-indigo;
  color: $main-color;
  pointer-events: none;
}

.outline {
  @include reset-button();
  @include textRegular-12();
  position: relative;
}
