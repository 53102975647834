// FONT FAMILY

$font-family-roboto: "Ubuntu", sans-serif;

// COLORS

$main-color: rgba(255, 255, 255, 0.5);
$main-color-dull: rgba(255, 255, 255, 0.05);
$moon-purple: #252537;
$basic-white: #ffffff;
$basic-black: #151727;
$basic-orange: #e6c04e;
$basic-blue: #0178ff;
$dark-blue: #017bff;
$stormy-indigo: #42424f;
$dark-blue: #252537cc;
$dark-grey: #5d5d6c;
$bright-orange-gradient: linear-gradient(270deg, #F0B400 0.06%, #FFEEA4 53.49%, #A97B0D 100.64%);
$orange-gradient: linear-gradient(90deg, #edc35c 0%, #fae384 100%);
$purpur-gradient: linear-gradient(90deg, #420084 0%, #8C46FF 100%);
$platinum-gradient: linear-gradient(272deg, #958078 -3.85%, #f1dccc 59.4%, #b19681 100.65%);
$gold-gradient: linear-gradient(272deg, #efc760 -3.85%, #f9e080 59.4%, #ebc85a 100.65%);
$exclusive-gradient: linear-gradient(272deg, #b89054 -3.85%, #e7bb79 59.4%, #a87e4e 100.65%);
$premium-gradient: linear-gradient(272deg, #818181 -3.85%, #d9d9d9 59.4%, #ababab 100.65%);
$vip-gradient: linear-gradient(260deg, #ab434f -10.43%, #e392aa 76.94%);
$dubai-gradient: linear-gradient(272deg, #7a5e3c -3.85%, #ccae8a 59.4%, #a88965 100.65%);
$virgin-gradient: linear-gradient(261deg, #d9a829 10.77%, #f3d26e 89.93%);
$golden-sunset-gradient: linear-gradient(270deg, #e6c04e 0%, #feec90 53.65%, #e7c14f 100%);

// FONT WEIGHTS

$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;

// ANIMATIONS

// BOX-SHADOWS

$orange-box-shadow: 0 0 63px 0 rgba(230, 192, 78, 0.46);

// TEXT-SHADOWS

$text-shadow-platinum: 0 0 15px #cdb5a2;
$text-shadow-gold: 0 0 15px #feec90;
$text-shadow-exclusive: 0 0 15px #ffcc7e;
$text-shadow-premium: 0 0 15px #d1d1d1;
$text-shadow-vip: 0 0 15px #ff7da1;
$text-shadow-dubai: 0 0 15px #c8aa86;
$text-shadow-virgin: 0 0 15px #e6c04e;
