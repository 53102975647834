@import "../../../scss/mixins/text.scss";
@import "../../../scss/variables.scss";

.wrapper {
  animation: fadeInUp 1s forwards;
  background: #252537;
  background: linear-gradient(#252537 0 0) padding-box,linear-gradient(#5D5D6C, #5D5D6C00) border-box;
  border: 2px solid transparent;
  border-radius: 8px;;
  opacity: 0;
}

.buyed {
  @include textRegular-11 ();
  align-items: center;
  background-color: #e6c04e;
  border-radius: 6px;
  color: #151727;
  display: inline-flex;
  flex-shrink: 0;
  gap: 10px;
  height: 18px;
  padding: 1px 5px;
}

.last_child {
    grid-column: 1 / -1;
  
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.header {
  display: flex;
  justify-content: space-between;
}

.name {
  @include textMontserratExtraBold-22();
  letter-spacing: 0.77px;
  margin-top: 10px;
  text-transform: uppercase;
}

.dusty_rose {
  .text_color {
    background: linear-gradient(272deg, #958078 -3.85%, #f1dccc 59.4%, #b19681 100.65%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 0 15px #cdb5a2;
  }
}

.lemon_chiffon {
  .text_color {
    background: linear-gradient(272deg, #e8c251 -3.85%, #fae384 59.4%, #edc35b 100.65%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 0 15px #feec90;
  }
}

.peach {
  .text_color {
    background: linear-gradient(272deg, #b89054 -3.85%, #e7bb79 59.4%, #a87e4e 100.65%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 0 15px #ffcc7e;
  }
}

.silver {
  .text_color {
    background: linear-gradient(272deg, #818181 -3.85%, #d9d9d9 59.4%, #ababab 100.65%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 0 15px #d1d1d1;
  }
}

.coral {
  .text_color {
    background: linear-gradient(260deg, #ab434f -10.43%, #e392aa 76.94%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 0 15px #ff7da1;
  }
}

.camel {
  .text_color {
    background: linear-gradient(272deg, #7a5e3c -3.85%, #ccae8a 59.4%, #a88965 100.65%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 0 15px #c8aa86;
  }
}

.purple {
  .text_color {
    background: linear-gradient(261deg, #842BE1 10.77%, #AF7EFF 89.93%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 0 15px #AE7CFF;
  }
}

.mustard {
  background: $virgin-gradient;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: $text-shadow-virgin;
}

.description {
  @include textRegular-12();
  color: #d2bdad;
  min-height: 42px;
}

.bottom_container {
  display: flex;
  justify-content: space-between;
  margin-top: auto
} 

.sale {
  @include textRegular-12 ();
}

.hit {
  @include textRegular-11();
  align-items: center;
  background-color: $basic-orange;
  border-radius: 6px;
  color: $basic-black;
  display: inline-flex;
  flex-shrink: 0;
  gap: 10px;
  height: 18px;
  padding: 1px 5px;
}

.quantity {
  @include textRegular-11();
  align-items: center;
  background: #42424f;
  border-radius: 6px;
  color: $basic-white;
  display: inline-flex;
  flex-shrink: 0;
  gap: 10px;
  height: 18px;
  padding: 1px 5px;
}

.price {
  @include textRegular-16();
  color: $basic-white;
  margin-top: auto;

  &.active {
    color: #E5BF50;
  }
}

.link {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 13px 9px;
}
