@import "../../../scss/mixins/text.scss";
@import "../../../scss/variables.scss";

.list {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(2, 48.8%);
  grid-template-rows: repeat(3, 1fr);
  margin-top: 15px;
}

.loader {
  align-items: center;
  display: flex;
  justify-content: center;
}
