@import "../variables.scss";

@mixin basic-text {
  color: $main-color;
  font-family: $font-family-roboto;
  font-style: normal;
  line-height: normal;
}

@mixin textRegular-12 {
  @include basic-text();
  font-size: 12px;
  font-weight: $font-weight-regular;
}

@mixin textRegular-16 {
  @include basic-text();
  font-size: 16px;
  font-weight: $font-weight-medium;
}

@mixin textRegular-14 {
  @include basic-text();
  font-size: 14px;
}

@mixin textBold-16 {
  @include basic-text();
  font-size: 16px;
  font-weight: $font-weight-bold;
}

@mixin textRegular-10 {
  @include basic-text();
  font-size: 10px;
}

@mixin textRegular-11 {
  @include basic-text();
  font-size: 11px;
}

@mixin textMontserratExtraBold-22 {
  @include basic-text();
  font-family: Montserrat, sans-serif;
  font-size: 22px;
  font-weight: $font-weight-extra-bold;
}

@mixin textMedium-20 {
  @include basic-text();
  font-size: 20px;
  font-weight: $font-weight-medium;
}
