@mixin vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin gradient-text {
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: $text-shadow-platinum;
}

@mixin globalCenter {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
}
