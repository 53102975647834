@import "../../scss/variables.scss";
@import "../../scss/mixins/text.scss";

.header {
  align-items: center;
  display: flex;
  gap: 13px;
  padding-left: 10px;
  padding-top: 7px;
}

.content {
  align-items: center;
  display: flex;
  gap: 16px;
}

.loader {
  margin-top: 50%;
}

.main {
  padding: 0 15px 21px;
}

.button_container {
  display: flex;
  gap: 10px;
  margin-top: 6px;
}

.button {
  padding: 8px 16px;
  width: 198px;
  word-break: break-all;
}

.footer {
  margin-top: auto;
  padding: 0 15px 21px;
}

.footer_text {
  @include textRegular-12();
  color: $basic-orange;
  margin-top: 14px;
  word-break: break-word;
}

.footer_text_grey {
  @include textRegular-12();
  color: rgba(255, 255, 255, 0.5);
  margin-top: 14px;
  word-break: break-word;
}

.icons {
  display: flex;
  gap: 17px;
}

.text {
  @include textRegular-12();
  line-height: 123.5%;
  max-width: 163px;
}

.orange_text {
  color: $basic-orange;
}

