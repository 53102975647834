body {
  background: linear-gradient(59deg, #121423 -1.16%, #1f1d32 87.22%);
  margin: 0 auto;
  max-width: 375px;
  text-size-adjust: none;
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.global_center {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
}

.orange_text {
  color: $basic-orange;
}

* {
  box-sizing: border-box;
  color: white;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 0;
  text-decoration: none;
}

img {
  height: auto;
  width: 100%;
}
