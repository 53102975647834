@import "../../../scss/mixins/text.scss";
@import "../../../scss/variables.scss";
@import "../../../scss/mixins/reset.scss";

.header {
  align-items: center;
  display: flex;
  margin-bottom: 10px;
  padding: 0 12px;
}

.link {
  align-items: center;
  background: #252537;
  border-radius: 0 0 16px 16px;
  display: flex;
  gap: 11px;
  justify-content: center;
  padding: 13px 60px;
  width: 100%;
}

.text {
  @include textRegular-14();
  color: $basic-white;
}
