@import "../../../scss/variables.scss";

.loader {
  align-items: center;
  display: flex;
  justify-content: center;
  left: 0;
  overflow: auto;
  top: 0;
  width: 100%;
}

.lds {
  display: flex;
  height: 80px;
  position: relative;
  width: 80px;
}

.lds div {
  animation: lds 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  background: $basic-white;
  display: inline-block;
  left: 50%;
  position: absolute;
  top: 50%;
  width: 16px;
}

.lds div:nth-child(1) {
  animation-delay: -0.24s;
  left: 8px;
}

.lds div:nth-child(2) {
  animation-delay: -0.12s;
  left: 32px;
}

.lds div:nth-child(3) {
  animation-delay: 0;
  left: 56px;
}
@keyframes lds {
  0% {
    height: 64px;
    top: 8px;
  }

  50%,
  100% {
    height: 32px;
    top: 24px;
  }
}
