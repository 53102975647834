.countries_container {
  padding: 5px;
  position: relative;
  z-index: 6;
}

.countries_list {
  display: none;
  padding: 5px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 5;
}

.countries_item {
  cursor: pointer;
}

.countries_container_active {
  background: rgba(255, 255, 255, 0.05);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.show_countries_list {
  backdrop-filter: blur(13px);
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  display: block;
}
